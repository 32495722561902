<template>
	<div class="partial partial--my-applications my-applications">
		<my-banner />

		<div class="partial__heading">
			<ion-icon name="apps" size="large" class="partial__icon"></ion-icon>
			<h1 class="partial__title">My Resources</h1>
		</div>

		<div class="partial__body">
			<list-my-applications :applications="sortedApplications" :user="me" :access="true" />
		</div>

		<p>&nbsp;</p>
		<br />
		<div class="partial__heading">
			<ion-icon name="apps" size="large" class="partial__icon"></ion-icon>
			<h1 class="partial__title">Available Resources</h1>
		</div>

		<div class="partial__body">
			<list-my-applications :applications="otherApplications" />
		</div>
	</div>
</template>

<script>
import { ApplicationStatus } from '@/lib/enums'
import { invert } from 'lodash'
import ListMyApplications from '@/components/ListMyApplications'
import MyBanner from '@/components/MyBanner'

export default {
	name: 'partialMyApplications',
	components: {
		ListMyApplications,
		MyBanner,
	},
	metaInfo: () => ({
		title: 'My Resources',
	}),
	asyncData: ({ store }) => {
		return store.dispatch('application/list')
	},
	data: () => ({
		sortOrder: [
			'ARC Pricing',
			'ARC Updates',
			'Bond Insight',
			'BVAL',
			'RECON',
			'Banker to Banker',
			'Market Commentary',
			'Market Rates',
			'Community Bank Podcast',
		],
		staticApplications: [
			{
				icon: 'glasses-outline',
				name: 'Banker to Banker',
				description: 'Get the latest data, strategies, and tactics that can save your bank resources and provide performance-enhancing ideas to keep you ahead of the competition.',
				url: 'https://southstatecorrespondent.com/category/banker-to-banker/?utm_source=services&utm_medium=dashboard&utm_campaign=tile+link',
				resource: true,
			},
			{
				icon: 'chatbubbles-outline',
				name: 'Market Commentary',
				description: 'Market commentary and research articles on the overall economy as well as opportunities and risks in the fixed income markets.',
				url: 'https://southstatecorrespondent.com/category/market-insights-commentary/?utm_source=services&utm_medium=dashboard&utm_campaign=tile+link',
				resource: true,
			},
			{
				logo: require('@/assets/logos/arc-updates.svg'),
				name: 'ARC Updates',
				description: `Get the latest ARC updates and see how the program is helping community banks book more profitable loans.`,
				url: `https://southstatecorrespondent.com/arc-hub/arc-updates/?utm_source=services&utm_medium=dashboard&utm_campaign=tile+link`,
				resource: true
			},
			{
				icon: 'mic-outline',
				name: 'Community Bank Podcast',
				description: 'The podcast dedicated to helping community bankers grow themselves, their team, and their profits.',
				url: 'https://southstatecorrespondent.com/podcasts/?utm_source=services&utm_medium=dashboard&utm_campaign=tile+link',
				resource: true,
			}
		]
	}),
	computed: {
		me() {
			return this.$store.getters['user/me']
		},
		applications() {
			const applications = this.$store.getters['application/applications']
			return applications.filter(a => a.status !== invert(ApplicationStatus).Inactive ).map(a => {
				try {
					a.logo = require(`@/assets/logos/${a.appId}.svg`)
				} catch (error) {
					a.logo = require(`@/assets/logos/blank.png`)
				}
				return a
			})
		},
		myApplications() {
			const me = this.$store.getters['user/me']

			me.permissions = me.permissions || {}
			me.permissions.bval = me.permissions.bval || me.permissions.bval_ssb
			const myApplications = this.applications.filter(a => me.permissions[a.appId])
			return [ ...myApplications, ...this.staticApplications]
		},
		sortedApplications() {
			let sorted = []
			for (let name of this.sortOrder) {
				const idx = this.myApplications.findIndex(m => m.name.toLowerCase() == name.toLowerCase())
				if (idx > -1) {
					const match = this.myApplications.splice(idx, 1)
					sorted = [...sorted, ...match]
				}
			}
			sorted = [...sorted, ...this.myApplications]

			return sorted
		},
		otherApplications() {
			const me = this.$store.getters['user/me']

			me.permissions = me.permissions || {}
			return this.applications.filter(a => !me.permissions[a.appId] && a.status !== invert(ApplicationStatus).Restricted)
		},
	},
}
</script>

<style scoped lang="scss">

</style>
