<template>
	<div class="view view--main main">
		<router-view class="main__nav" name="nav" />
		<div class="main__body">
			<router-view class="main__header" name="header" />
			<router-view class="main__content" name="content" />
		</div>
		<router-view class="main__footer" name="footer" />
		<modal @close="hideModal" :open="modalVisible" :title="modalTitle">
			<router-view class="main__modal" slot="content" name="modal" />
		</modal>
	</div>
</template>

<script>
import Modal from '@/components/Modal'

export default {
	name: 'viewMain',
	components: {
		Modal,
	},
	computed: {
		modalVisible() {
			return this.$route.meta.modalVisible
		},
		modalTitle() {
			return this.$route.meta.modalTitle
		},
	},
	methods: {
		hideModal(clicked) {
			if (clicked) {
				this.$router.back()
			}
		},
	},
}
</script>

<style scoped lang="scss">
.main {
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	width: 100%;

	@include modules.media-query('desktop') {
		flex-direction: row;
	}

	&__body {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	&__nav {
		max-width: 100%;
		width: 100%;

		// @include modules.media-query('desktop') {
		// 	max-width: 300px;
		// }

		@include modules.media-query-min-width('desktop-xs') {
			max-width: 240px;
		}

		@include modules.media-query-min-width('desktop-sm') {
			max-width: 300px;
		}
	}

	&__content {
		flex: 1;
	}
}
</style>
