<template>
	<div class="list list--my-applications list-my-applications">
		<card
			class="list-my-applications__item"
			:key="`my_${key}`"
			v-for="(item, key) in applications"
		>
			<div class="list-my-applications__item-logo">
				<img :src="item.logo" v-if="item.logo" />
				<ion-icon :name="item.icon" v-if="item.icon" />
			</div>
			<div class="list-my-applications__item-title"> {{ item.name }}</div>
			<div class="list-my-applications__item-description">{{ item.description }}</div>
			<div class="list-my-applications__item-tag" v-if="item.status == 'beta'">beta</div>
			<a class="list-my-applications__item-link" :href="`${item.url}?token=${user.token}`" rel="noopener" v-if="access && !item.resource">Launch Application</a>
			<a @click="requestPrompt(item)" rel="noopener" class="list-my-applications__item-link" v-if="!access && !item.resource">Request More Info</a>
			<a :href="item.url" rel="noopener" class="list-my-applications__item-link" :target="item.appId ? '_self' : '_blank'" v-if="item.resource">View Resource</a>
		</card>

	</div>
</template>

<script>
import Card from '@/components/Card'

export default {
	name: 'listApplications',
	components: {
		Card,
	},
	props: {
		access: Boolean,
		applications: Array,
		user: Object,
	},
	methods: {
		requestPrompt(app) {
			this.$confirm(`Would you like us to send you more information about ${app.name}?`, () => {
				this.$store.dispatch('application/requestInfo', app.appId)
					.then((res) => {
						this.$notice(`Thank you! Some one will be in touch shortly.`)
					})
					.catch((error) => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
					.finally(() => {

					})
			}, {
				title: `Request More Info`,
				acceptLabel: `Yes, Send`,
			})
		},
	},
}
</script>

<style scoped lang="scss">
@use '@material/elevation';

.list-my-applications {
	@include modules.gutter('grid-column-gap');
	@include modules.gutter('grid-row-gap');

	display: grid;
	grid-template-columns: repeat(2, 1fr);

	@include modules.media-query('phone') {
		grid-template-columns: 1fr;
	}

	@include modules.media-query('tablet') {
		grid-template-columns: repeat(2, 1fr);
	}

	@include modules.media-query-min-width('desktop-sm') {
		grid-template-columns: repeat(3, 1fr);
	}

	&__item {
		// @include elevation.elevation(20, rgba(0, 0, 0, 0.15), -0.1);
		@include modules.gutter('padding-top');

		align-items: center;
		border-radius: modules.$border-radius-lg;
		display: flex;
		flex-direction: column;

		&-logo {
			@include modules.gutter('margin-bottom', .25);
			ion-icon {
				color: modules.color_('primary');
				@include modules.fontSize(70px);
			}

			img {
				height: 70px;
				width: 70px;
				color: modules.color_('primary');
			}
		}

		&-title {
			@include modules.gutter('padding-left');
			@include modules.gutter('padding-right');
			@include modules.fontSize(24px);
			font-weight: 500;
			line-height: 1.35;
			text-align: center;
		}

		&-tag {
			@include modules.fontSize(12px);
			background-color: modules.color_('primary');
			border-radius: 3px;
			color: modules.color_('primary', 'text');
			padding: 2px 6px;
			position: absolute;
			right: 1rem;
			text-transform: uppercase;
			top: 1rem;
		}

		&-description {
			@include modules.gutter('padding');
			@include modules.gutter('padding-top', .5);
			color: modules.color_('text');
			flex: 1;
			@include modules.fontSize(15px);
			line-height: 1.5;
			text-align: center;
		}

		&-link {
			border-top: 1px solid rgba(modules.color_('border'), 0.5);
			color: modules.color_('primary');
			@include modules.fontSize(14px);
			font-weight: 600;
			letter-spacing: 2.2px;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			transition: all 150ms linear;
			padding: 1.5rem;
			width: 100%;

			&:hover {
				color: modules.color_('primary', 'light');
			}
		}
	}
}
</style>
